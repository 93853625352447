import {
  ALL,
  REQUIRED_LOGIN,
  uiRoleAuthenticateMapping
} from '../../auth/allowAuthMapping'
const CampaignList = () =>
  import(/* webpackChunkName: "Campaign" */ './pages/CampaignList.vue')
const CreateCampaign = () =>
  import(/* webpackChunkName: "Campaign" */ './pages/CreateCampaign.vue')
const EditCampaign = () =>
  import(/* webpackChunkName: "Campaign" */ './pages/EditCampaign.vue')
const CampaignDetail = () =>
  import(/* webpackChunkName: "Campaign" */ './pages/CampaignDetail.vue')

export default {
  path: 'campaigns',
  component: { render: h => h('router-view') },
  children: [
    {
      path: '',
      name: 'CampaignList',
      component: CampaignList,
      meta: {
        allowedRoles: [ALL],
        allowedAuth: REQUIRED_LOGIN
      }
    },
    {
      path: 'new',
      name: 'CampaignCreate',
      component: CreateCampaign,
      meta: {
        allowedRoles: uiRoleAuthenticateMapping.CampaignCreate,
        allowedAuth: REQUIRED_LOGIN
      }
    },
    {
      path: ':campaignId/edit',
      name: 'CampaignEdit',
      component: EditCampaign,
      meta: {
        allowedRoles: uiRoleAuthenticateMapping.CampaignEdit,
        allowedAuth: REQUIRED_LOGIN
      },
      props: true
    },
    {
      path: ':campaignId/detail',
      name: 'CampaignDetail',
      component: CampaignDetail,
      meta: {
        allowedRoles: [ALL],
        allowedAuth: REQUIRED_LOGIN
      },
      props: true
    }
  ]
}
