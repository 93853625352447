import Vue from 'vue'
import { PUBLIC, REQUIRED_LOGOUT } from './allowAuthMapping'

export const userIsAllowed = (allowedRoles = []) => {
  const { isLogin } = Vue.config

  if (!isLogin) {
    return false
  }

  if (allowedRoles.includes('*')) {
    return true
  }

  const userRole = (Vue.config && Vue.config.user && Vue.config.user.role) || ''

  return allowedRoles.includes(userRole)
}

export const authenticate = ({ meta, fullPath }) => {
  const { allowedAuth = '', allowedRoles = [] } = meta
  const { isLogin } = Vue.config

  if (
    allowedAuth === PUBLIC ||
    (allowedAuth === REQUIRED_LOGOUT && !isLogin) ||
    (userIsAllowed(allowedRoles) && allowedAuth !== REQUIRED_LOGOUT)
  ) {
    return true
  }

  if (isLogin) {
    return { path: '/' }
  }
  return {
    path: '/login',
    query: { redirect: fullPath }
  }
}
