import Vue from 'vue'
import router from 'configs/router'
import i18n from 'configs/i18n'
import store from 'configs/store'
import 'configs/ui'
import 'configs/service'

Vue.config.productionTip = false

new Vue({
  router,
  i18n,
  data: { store }
}).$mount('#app')
