export default [
  {
    name: 'Campaign',
    functionName: 'CampaignRead',
    icon: 'collections_bookmark',
    pathName: 'CampaignList'
  },
  {
    name: 'Task',
    functionName: 'TaskRead',
    icon: 'border_color',
    pathName: 'TaskList'
  },
  {
    name: 'Template',
    functionName: 'TemplateRead',
    icon: 'format_shapes',
    pathName: 'TemplateList'
  },
  {
    name: 'Role',
    functionName: 'RoleRead',
    icon: 'account_box',
    pathName: 'RoleList'
  },
  {
    name: 'Frequency',
    functionName: 'Frequency',
    icon: 'settings',
    pathName: 'Frequency'
  }
]
