import Vue from 'vue'
import serviceWrapper from '@/utils/service'

const fetchSegmentList = async (params = {}) => {
  const segmentRequired = params.segmentRequired
  delete params.segmentRequired
  const listRes = await Vue.$http({
    method: 'get',
    url: `/api/v1/companies/${params.companyId}/segments`
  })
  const existingSegments = new Set(listRes.data.map(segment => segment.id))
  for (const segmentId of segmentRequired) {
    if (existingSegments.has(segmentId)) continue
    const { data } = await fetchSegmentDetail({
      ...params,
      segmentId: segmentId
    })
    listRes.data.push(data)
    existingSegments.add(segmentId)
  }
  return listRes
}

const fetchSegmentDetail = (params = {}) =>
  Vue.$http({
    method: 'get',
    url: `/api/v1/companies/${params.companyId}/segments/${params.segmentId}`
  })

const createCampaign = (params = {}) =>
  Vue.$http({
    method: 'post',
    url: `/api/v1/companies/${params.companyId}/campaigns`,
    data: params
  })

const updateCampaign = (params = {}) =>
  Vue.$http({
    method: 'patch',
    url: `/api/v1/companies/${params.companyId}/campaigns/${params.id}`,
    data: params
  })

const fetchCampaignList = (params = {}, offset = 0, limit = 10) =>
  Vue.$http({
    method: 'get',
    url: `/api/v1/companies/${params.companyId}/campaigns`,
    params: {
      offset,
      limit,
      ...params
    }
  })

const fetchCampaignDetail = (params = {}) =>
  Vue.$http({
    method: 'get',
    url: `/api/v1/companies/${params.companyId}/campaigns/${params.campaignId}`
  })

const deleteCampaign = (params = {}) =>
  Vue.$http({
    method: 'delete',
    url: `/api/v1/companies/${params.companyId}/campaigns/${params.id}`
  })

export default serviceWrapper({
  createCampaign,
  deleteCampaign,
  fetchCampaignList,
  fetchCampaignDetail,
  fetchSegmentDetail,
  fetchSegmentList,
  updateCampaign
})
