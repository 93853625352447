<template>
<v-app>
  <slot></slot>
</v-app>
</template>

<script>
export default {
  name: 'x-app'
};
</script>

<style lang="stylus" scoped> 
</style>
