import { ALL, REQUIRED_LOGIN } from '../../auth/allowAuthMapping'
const Dashboard = () =>
  import(/* webpackChunkName: "Home" */ './pages/Dashboard.vue')

export default {
  path: 'dashboard',
  component: Dashboard,
  meta: {
    allowedRoles: [ALL],
    allowedAuth: REQUIRED_LOGIN
  }
}
