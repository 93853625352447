import Vue from 'vue'

export const createUser = params =>
  Vue.$http({
    method: 'post',
    url: `/api/v1/companies/${params.companyId}/users`,
    data: params
  })

export const fetchUserList = (params, offset = 0, limit = 10) =>
  Vue.$http({
    method: 'get',
    url: `/api/v1/companies/${params.companyId}/users`,
    params: {
      offset,
      limit,
      ...params
    }
  })

export const updateUser = params => {
  const data = {}
  if (params.password) data.password = params.password
  if (params.name) data.name = params.name
  return Vue.$http({
    method: 'patch',
    url: `/api/v1/companies/${params.companyId}/users/${params.userId}`,
    data
  })
}

export const deleteUser = params =>
  Vue.$http({
    method: 'delete',
    url: `/api/v1/companies/${params.companyId}/users/${params.userId}`
  })
