<template>
  <v-layout v-bind="layoutScheme">
    <v-dialog
      v-model="dialog"
      v-bind="dialogScheme"
    >
      <v-card class="confirmDialog">
        <v-card-title class="headline header">
          <slot
            v-if="hasTitleSlot"
            name="title"
          />
          <span v-else-if="title"> {{ title }} </span>
        </v-card-title>
        <v-card-text class="content">
          <slot
            v-if="hasMessageSlot"
            name="message"
          />
          <span v-else> {{ message }} </span>
        </v-card-text>
        <v-card-actions class="footer">
          <!-- <v-spacer /> -->
          <v-btn
            v-for="(button, ind) in actionButtons"
            :key="ind"
            v-bind="button"
            depressed
            large
            @click="e => buttonClicked(e, button)"
          >
            {{ button.text }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
export default {
  name: 'ConfirmDialog',
  props: {
    layoutScheme: {
      type: Object,
      default: () => ({ row: true, 'justify-center': true })
    },
    dialogScheme: {
      type: Object,
      default: () => ({ persistent: true, 'max-width': 420 })
    },
    title: { type: String, default: 'Please confirm:' },
    message: { type: String, default: '' },
    onConfirm: { type: Function, default: () => {} },
    onCancel: { type: Function, default: () => {} },
    buttons: { type: Array, default: null },
    onClose: { type: Function, default: () => {}, required: true }
  },
  data () {
    return {
      dialog: true
    }
  },
  computed: {
    hasTitleSlot () {
      return !!this.$slots.title
    },
    hasMessageSlot () {
      return !!this.$slots.message
    },
    actionButtons: function () {
      if (this.buttons) return this.buttons
      return [
        {
          text: 'Cancel',
          outline: true,
          class: 'btnInfo',
          onClick: this.onCancel
        },
        {
          text: 'Remove',
          class: 'btnWarning',
          onClick: this.onConfirm
        }
      ]
    }
  },
  methods: {
    buttonClicked (e, button) {
      this.dialog = false
      this.onClose()
      if (typeof button.onClick === 'function') {
        return button.onClick(e)
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.confirmDialog {
  padding: 20px;

  .header {
    font-size: 20px !important;
  }

  .content {
    color: $label-grey;
  }

  .footer {
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 10px;

    button {
      margin: 6px 8px;
    }
  }
  @media(max-width: 480px) {
    .footer {
      flex-direction: column-reverse;
    }
    button {
      width: 100%;
      margin: 6px 0 !important;
    }
  }
}
</style>
