import { getCookie } from '../auth/cookie.js'
import { numberToOrdinalString } from '@/utils/number'

const TIMEZONE = {
  Philippine: 8,
  Jakarta: 7,
  Ho_Chi_Minh: 7
}

const TASK_ALLOWED_PERIOD = {
  Philippine: {
    start: {
      hour: 8,
      minutes: 0
    },
    end: {
      hour: 24,
      minutes: 0
    }
  },
  Jakarta: {
    start: {
      hour: 8,
      minutes: 0
    },
    end: {
      hour: 24,
      minutes: 0
    }
  },
  Ho_Chi_Minh: {
    start: {
      hour: 9,
      minutes: 30
    },
    end: {
      hour: 24,
      minutes: 0
    }
  }
}

export const isTaskTimeAllowed = dateObj => {
  if (!dateObj) return false
  const d = convertToCompanyTime(dateObj)
  const timezone = getCookie('oTimezone')
  const hour = d.getHours()
  const minutes = d.getMinutes()
  const dayMinutes = hourToMinutes(hour) + minutes
  const { start, end } = TASK_ALLOWED_PERIOD[timezone]
  const startDayMinutes = hourToMinutes(start.hour) + start.minutes
  const endDayMinutes = hourToMinutes(end.hour) + end.minutes
  return dayMinutes >= startDayMinutes && dayMinutes < endDayMinutes
}

export const hourToMinutes = h => h * 60
export const hourToMillisecond = h => h * 60 * 60 * 1000

export const formatDatetimeFromISOString = isoString => {
  const timezone = getCookie('oTimezone')
  if (!timezone || !isoString) {
    throw new Error('Params are required: timezone(string), datetime(string)')
  }
  const timezoneOffset = TIMEZONE[timezone]
  if (!timezoneOffset) throw new Error(`Timezone not valid: ${timezone}`)

  const dateObj = new Date(
    new Date(isoString).valueOf() + hourToMillisecond(timezoneOffset)
  )
  const year = dateObj.getUTCFullYear()
  const month = (dateObj.getUTCMonth() + 1).toString().padStart(2, '0')
  const date = dateObj
    .getUTCDate()
    .toString()
    .padStart(2, '0')
  const hour = dateObj
    .getUTCHours()
    .toString()
    .padStart(2, '0')
  const minute = dateObj
    .getUTCMinutes()
    .toString()
    .padStart(2, '0')
  const second = dateObj
    .getUTCSeconds()
    .toString()
    .padStart(2, '0')
  return `${year}-${month}-${date} ${hour}:${minute}:${second}`
}

export const localDateToISOString = dateObj => {
  return convertToLocalTime(dateObj).toISOString()
}

export const convertToLocalTime = dateObj => {
  const timezone = getCookie('oTimezone')
  const timezoneOffset = TIMEZONE[timezone]
  if (!timezoneOffset) throw new Error(`Timezone not valid: ${timezone}`)

  const localTimezoneOffset = dateObj.getTimezoneOffset() / -60
  return new Date(
    dateObj.valueOf() - hourToMillisecond(timezoneOffset - localTimezoneOffset)
  )
}

export const convertToCompanyTime = dateObj => {
  const timezone = getCookie('oTimezone')
  const timezoneOffset = TIMEZONE[timezone]
  if (!timezoneOffset) throw new Error(`Timezone not valid: ${timezone}`)

  const localTimezoneOffset = dateObj.getTimezoneOffset() / -60
  return new Date(
    dateObj.valueOf() + hourToMillisecond(timezoneOffset - localTimezoneOffset)
  )
}

export const toDateObj = isoString => {
  return convertToCompanyTime(isoString ? new Date(isoString) : new Date())
}

export const composeScheduleHint = (schedule = '', date) => {
  if (!date || !(date instanceof Date)) {
    throw new Error('Date is required and must be a Date object')
  }
  if (!schedule || !['Daily', 'Weekly', 'Monthly'].includes(schedule)) {
    return 'Not Repeat'
  }
  const repeatWording = (() => {
    switch (schedule) {
      case 'Daily':
        return 'Every Day'
      case 'Weekly':
        // FIXME: change according to locale setting
        return date.toLocaleDateString('en-US', { weekday: 'long' })
      case 'Monthly':
        return `Every ${numberToOrdinalString(date.getDate())}`
    }
  })()
  return `Sending ${schedule} on ${repeatWording}`
}
