const cnameMapping = [{ t: 'token' }, { e: 'email' }]

const getCnameByKey = key => {
  for (let mappingObject of cnameMapping) {
    const [cname, originName] = Object.entries(mappingObject)[0]
    if (originName === key) return cname
  }

  return null
}

const setCookieKeyVal = (cname, cvalue, exdays = 7) => {
  var d = new Date()
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
  var expires = 'expires=' + d.toUTCString()
  window.document.cookie = `${cname}=${cvalue};${expires};path=/`
}

export const setCookie = token => {
  if (!token) return (window.document.cookie = '')

  const mappingCookieObj = { token }

  Object.entries(mappingCookieObj).forEach(element => {
    const [originName, val] = element
    const _cname = getCnameByKey(originName)

    if (_cname) setCookieKeyVal(_cname, val)
  })
}

export const getCookie = cname => {
  if (!cname) return ''

  const name = cname + '='
  const decodedCookie = decodeURIComponent(window.document.cookie)
  const ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

export const deleteAllCookies = () => {
  return setCookieKeyVal('t', '')
}
