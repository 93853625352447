import Vue from 'vue'
import serviceWrapper from '@/utils/service'

const createContactRule = (params = {}) =>
  Vue.$http({
    method: 'post',
    url: `/api/v1/companies/${params.companyId}/contactRules`,
    data: params
  })

const fetchContactRules = (params = {}, offset = 0, limit = 10) =>
  Vue.$http({
    method: 'get',
    url: `/api/v1/companies/${params.companyId}/contactRules`,
    params: {
      offset,
      limit,
      ...params
    }
  })

const updateContactRule = (params = {}) =>
  Vue.$http({
    method: 'patch',
    url: `/api/v1/companies/${params.companyId}/contactRules/${params.id}`,
    data: params
  })

export default serviceWrapper({
  createContactRule,
  fetchContactRules,
  updateContactRule
})
