export default {
  registerService (object, module) {
    Object.defineProperty(object, `$${module.name}`, {
      get () {
        return module.services
      }
    })
  },

  registerLang (i18n, module) {
    for (let name in module.langs) {
      i18n.mergeLocaleMessage(name, module.langs[name])
    }
  }
}
