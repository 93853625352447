import Vue from 'vue'
import VueStash from 'vue-stash'
Vue.use(VueStash)

let store = {
  env: '',
  lang: 'en',
  user: null,
  errorNotification: null,
  successNotification: null,
  confirmDialog: [],
  builderProperty: {
    rowSectionID: 0,
    rowSectionList: [],
    gallery: [],
    draggingSectionId: null
  },
  loading: false
}

export default store
