import * as components from './components';
import './styles/base.styl';

const install = function(Vue) {
  for (const key in components) {
    const component = components[key];
    if (component.name) {
      Vue.component(component.name, component);
    }
  }
};

if (typeof window !== 'undefined' && window.Vue) {
  install(window.Vue);
}

export default {
  install
};
