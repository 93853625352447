import { getCookie } from '@/auth/cookie'

export default funcs =>
  Object.keys(funcs).reduce((obj, key) => {
    obj[key] = appendCompanyToParams(funcs[key])
    return obj
  }, {})

export const appendCompanyToParams = func => params =>
  func({
    companyId: getCookie('oCompany'),
    ...params
  })
