import Vue from 'vue'
import I18n from 'vue-i18n'
import modules from 'modules'
import helper from './helper'

Vue.use(I18n)

const i18n = new I18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: {}
})

modules.forEach(module => {
  helper.registerLang(i18n, module)
})

export default i18n
