import {
  REQUIRED_LOGIN,
  uiRoleAuthenticateMapping
} from '../../auth/allowAuthMapping'
const FrequencyControl = () =>
  import(/* webpackChunkName: "Frequency" */ './pages/FrequencyControl.vue')
const { Frequency } = uiRoleAuthenticateMapping

export default {
  path: 'frequency',
  component: { render: h => h('router-view') },
  children: [
    {
      path: '',
      name: 'Frequency',
      component: FrequencyControl,
      meta: {
        allowedRoles: Frequency,
        allowedAuth: REQUIRED_LOGIN
      }
    }
  ]
}
