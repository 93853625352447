import { ALL, REQUIRED_LOGOUT } from '../../auth/allowAuthMapping'
const Login = () => import(/* webpackChunkName: "Login" */ './pages/Login.vue')
const LoginNew = () =>
  import(/* webpackChunkName: "User" */ './pages/LoginNew.vue')
const ForgotPassword = () =>
  import(/* webpackChunkName: "User" */ './pages/ForgotPassword.vue')
const ResetPassword = () =>
  import(/* webpackChunkName: "User" */ './pages/ResetPassword.vue')

export default {
  path: '/user',
  component: { render: h => h('router-view') },
  children: [
    {
      path: 'login',
      name: 'login',
      alias: '/login',
      component: Login,
      meta: {
        allowedRoles: [ALL],
        allowedAuth: REQUIRED_LOGOUT
      }
    },
    {
      path: 'loginnew',
      name: 'loginnew',
      alias: '/loginnew',
      component: LoginNew,
      meta: {
        allowedRoles: [ALL],
        allowedAuth: REQUIRED_LOGOUT
      }
    },
    {
      path: 'forgotpassword',
      name: 'forgotpassword',
      alias: '/forgotpassword',
      component: ForgotPassword,
      meta: {
        allowedRoles: [ALL],
        allowedAuth: REQUIRED_LOGOUT
      }
    },
    {
      path: 'resetpassword',
      name: 'resetpassword',
      alias: '/resetpassword',
      component: ResetPassword,
      meta: {
        allowedRoles: [ALL],
        allowedAuth: REQUIRED_LOGOUT
      }
    }
  ]
}
