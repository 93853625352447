// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("assets/landing_bg.webp");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".simple-layout {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "), linear-gradient(15deg, #2d2665, #e4fff9);\n  background-position: center center;\n  background-repeat: no-repeat;\n  background-size: cover;\n}\n.simple-layout .logo {\n  text-align: center;\n}\n.simple-layout .logo img {\n  width: 140px;\n  display: inline-block;\n}\n.simple-layout .subtitle {\n  color: #fff;\n  text-align: center;\n  margin: 16px auto 0;\n  font-weight: 700;\n}\n.simple-layout .copyright {\n  text-align: center;\n  margin-top: 16px;\n}\n@media screen and (max-width: 500px) {\n.simple-layout .logo img {\n    width: 86px;\n}\n.simple-layout p.subtitle {\n    margin: 16px auto;\n}\n}\n", ""]);
// Exports
module.exports = exports;
