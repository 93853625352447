import Vue from 'vue'
import Router from 'vue-router'
import modules from 'modules'

import Base from '../layouts/Base'
import Simple from '../layouts/Simple'
import Admin from '../layouts/Admin'

import { getCookie } from '../auth/cookie'
import { getCurrentUser } from '../auth/apis'
import { authenticate } from '../auth/middleware'
import store from './store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: Base,
      children: [
        {
          path: '/simplelayout',
          component: Simple,
          children: modules
            .filter(module => module.layout === 'Simple')
            .map(module => module.routes)
        },
        {
          path: '/',
          redirect: { name: 'CampaignList' },
          component: Admin,
          children: modules
            .filter(module => module.layout === 'Admin')
            .map(module => module.routes)
        },
        { path: '*', redirect: { name: 'login' } }
      ]
    }
  ],
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  const companyId = getCookie('oCompany')
  if (!companyId) {
    Vue.config.isLogin = false
    return authAndNext(to, from, next)
  }

  return getCurrentUser()
    .then(({ data }) => {
      if (!data.id) throw new Error('auth failed')
      Vue.config.user = {
        ...data,
        role: data.role.id
      }
      store.user = Vue.config.user
      store.env = data.env
      window.env = data.env // FIXME: Not a good practice, fixme if can
      Vue.config.isLogin = true
      authAndNext(to, from, next)
    })
    .catch(() => {
      Vue.config.isLogin = false
      authAndNext(to, from, next)
    })
})

const authAndNext = (to, from, next) => {
  const redirectRoutes = authenticate(to)

  if (redirectRoutes && redirectRoutes.path) {
    return next(redirectRoutes)
  }
  return next()
}

export default router
