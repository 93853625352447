export default [
  {
    name: 'Edit Profile',
    click: function (item, self) {
      self.linkTo('/account/profile')
    }
  },

  // hidden setting demo

  // {
  //   name: 'Setting demo',
  //   pathName: 'accountSetting',
  //   click: function(item, self) {
  //     self.linkTo({ name: item.pathName });
  //   }
  // },

  { divider: true },
  {
    name: 'Logout',
    click: function (item, self) {
      self.logout()
    }
  }
]
