import { getCookie } from '@/auth/cookie'
import { sectionType } from '@/components/SectionUI/const/sections'
import cashaloHeaderHtml from './components/SectionUI/const/html/cashalo/header.html'
import cashaloFooterHtml from './components/SectionUI/const/html/cashalo/footer.html'
import finmasHeaderHtml from './components/SectionUI/const/html/finmas/header.html'
import finmasFooterHtml from './components/SectionUI/const/html/finmas/footer.html'
import finiziHeaderHtml from './components/SectionUI/const/html/finizi/header.html'
import finiziFooterHtml from './components/SectionUI/const/html/finizi/footer.html'

const STATUS = {
  draft: 'draft',
  // inApproval: 'inApproval', // Hide this status for now, since we don't have approval process
  approved: 'approved',
  terminated: 'terminated'
}

const EXECUTE_STATUS = {
  notReady: 'notReady',
  ready: 'ready',
  executing: 'executing',
  terminated: 'terminated',
  failed: 'failed',
  successful: 'successful'
}

const VARIABLES = [
  'receiverName',
  'firstName',
  'lastName',
  'userName',
  'dynamicInformation1',
  'dynamicInformation2'
]

const TEMPLATE_KEYS = {
  LUCID_SID: 'lucidSid',
  SURVEY_URL: 'surveyUrl',
  SURVEY_URL_PAID: 'surveyUrlPaid',
  SHORT_URL: 'shortUrl'
}

const DEFAULT_PAGE_SIZE = 10

const dataPlatform = {
  BUFFER_HOUR: {
    develop: 0.0167,
    staging: 3,
    product: 3 // This should be greater than 1 hours. DataPlatform promised they will generate mailing list within 1 hours
  },
  URL: {
    development:
      'http://ec2-54-223-118-212.cn-north-1.compute.amazonaws.com.cn:8099',
    beta: 'http://ec2-54-223-118-212.cn-north-1.compute.amazonaws.com.cn:8099',
    develop:
      'http://ec2-54-223-118-212.cn-north-1.compute.amazonaws.com.cn:8099',
    staging:
      'http://ec2-54-223-118-212.cn-north-1.compute.amazonaws.com.cn:8099',
    product: 'http://one.oriente.com'
  }
}

const COMPANY_ID = {
  cashalo: 2,
  finmas: 3,
  finizi: 4,
  slash: 5
}

const APPLICATION = 'CRM'

const getRecordDataUrl = () => {
  const companyId = Number(getCookie('oCompany'))
  const domain = Object.keys(COMPANY_ID).find(
    key => COMPANY_ID[key] === companyId
  )
  return {
    recordDataUrl: {
      development: `https://api.record-data.cashya.com/develop/v1/domains/${domain}/applications/${APPLICATION}/recordData`,
      beta: `https://api.record-data.cashya.com/develop/v1/domains/${domain}/applications/${APPLICATION}/recordData`,
      develop: `https://api.record-data.cashya.com/develop/v1/domains/${domain}/applications/${APPLICATION}/recordData`,
      staging: `https://api.record-data.cashya.com/develop/v1/domains/${domain}/applications/${APPLICATION}/recordData`,
      product: `https://api.record-data.cashya.com/product/v1/domains/${domain}/applications/${APPLICATION}/recordData`
    }
  }
}

const sharedBuilderSections = [
  {
    name: 'Only Text',
    cover: require('@/assets/edmTemplate/section-text.jpg'),
    type: sectionType.TextElement
  },
  {
    name: 'Image',
    cover: require('@/assets/edmTemplate/section-image.jpg'),
    type: sectionType.ImageElement
  },
  // {
  //   name: 'Full Width Image',
  //   cover: require('@/assets/edmTemplate/section-image-full-width.jpg'),
  //   type: sectionType.ImageFullWidth
  // },
  {
    name: '2 Column Image',
    cover: require('@/assets/edmTemplate/section-image-2column.jpg'),
    type: sectionType.Image2Column
  },
  {
    name: '3 Column Image',
    cover: require('@/assets/edmTemplate/section-image-3column.jpg'),
    type: sectionType.Image3Column
  }
]
const getCoreApiBaseUrl = env => {
  const crmToCoreApiEnvMapping = {
    development:
      'https://stg-cdn-estore.oriente.com/core-image-api/beta/v1/images',
    develop: 'https://stg-cdn-estore.oriente.com/core-image-api/beta/v1/images',
    beta: 'https://stg-cdn-estore.oriente.com/core-image-api/beta/v1/images',
    staging: 'https://stg-cdn-estore.oriente.com/core-image-api/pp/v1/images',
    product: 'https://cdn-estore.oriente.com/core-image-api/prod/v1/images'
  }
  return crmToCoreApiEnvMapping[env] || 'beta'
}
const getCompanyConfig = ({ companyId, env = 'beta' }) => {
  companyId = Number(companyId || getCookie('oCompany'))
  const sharedImages = {
    banner: `${getCoreApiBaseUrl(env)}/crm/img-500-2000px.jpg`,
    bannerSquare: `${getCoreApiBaseUrl(env)}/crm/img-500-2000px-square.jpg`,
    jgsLogo: `${getCoreApiBaseUrl(env)}/crm/EDM_logo_JGS.png`,
    btnGooglePlay: `${getCoreApiBaseUrl(env)}/crm/EDM_btn_google.png`,
    btnAppStore: `${getCoreApiBaseUrl(env)}/crm/EDM_btn_appstore.png`,
    iconFacebook: `${getCoreApiBaseUrl(env)}/crm/icon_fb.png`,
    iconInstagram: `${getCoreApiBaseUrl(env)}/crm/icon_ins.png`,
    iconLinkedIn: `${getCoreApiBaseUrl(env)}/crm/icon_linkedin.png`,
    openTrackingImage: `${getCoreApiBaseUrl(env)}/crm/transparent.png`
  }
  switch (companyId) {
    case COMPANY_ID.cashalo:
      return {
        defaultImage: {
          ...sharedImages,
          companyLogo: `${getCoreApiBaseUrl(
            env
          )}/crm/EDM_logo_cashalo_higit_sa_cash_kasalo_v2.png`
        },
        defaultLink: {
          officialUrl: 'https://www.cashalo.com',
          googlePlay:
            'https://play.google.com/store/apps/details?id=com.oriente.cashalo',
          appStore: 'https://itunes.apple.com/ph/app/cashalo/id1346512953?mt=8',
          facebook: 'https://www.facebook.com/CashaloPH/',
          instagram: 'https://www.instagram.com/cashaloph/',
          linkedIn: 'https://www.linkedin.com/company/cashalo'
        },
        customerService: {
          email: 'hello@cashalo.com',
          phone: '(02)8-470-6888'
        },
        countryCode: '63',
        template: {
          header: cashaloHeaderHtml,
          footer: cashaloFooterHtml
        },
        style: {
          color: {
            primary: '#266BB7',
            secondary: '#0057AB'
          }
        },
        taskChannels: [
          'SMS',
          'EDM',
          'OUTBOUND',
          'APP_PUSH',
          'EXCLUSIVE_PRODUCTS'
        ],
        taskMessageType: [
          'compliance',
          'internal_marketing',
          'third_party_marketing'
        ],
        edmComponents: [
          ...sharedBuilderSections,
          {
            name: 'Only Button',
            cover: require('@/assets/edmTemplate/section-btn.jpg'),
            type: sectionType.ButtonElement
          }
        ]
      }
    case COMPANY_ID.finmas:
    case COMPANY_ID.slash: // TODO add slash config
      return {
        defaultImage: {
          ...sharedImages,
          companyLogo: `${getCoreApiBaseUrl(env)}/crm/EDM_logo_finmas.png`
        },
        defaultLink: {
          officialUrl: 'https://www.finmas.co.id/',
          googlePlay: 'https://go.onelink.me/JJXo/Finmas',
          appStore: 'https://finmas.onelink.me/4viC/5a614049',
          facebook: 'https://www.facebook.com/finmasid',
          instagram: 'https://www.instagram.com/finmasid/',
          linkedIn: 'https://www.linkedin.com/company/finmas-id/',
          twitter: 'https://twitter.com/finmascare'
        },
        customerService: {
          email: 'cs@finmas.co.id',
          phone: '(021) 509 999 88'
        },
        countryCode: '62',
        template: {
          header: finmasHeaderHtml,
          footer: finmasFooterHtml
        },
        style: {
          color: {
            primary: '#D51C29',
            secondary: '#00B3E3'
          }
        },
        taskChannels: ['SMS', 'EDM', 'OUTBOUND', 'APP_PUSH'],
        taskMessageType: ['compliance', 'internal_marketing'],
        edmComponents: [
          ...sharedBuilderSections,
          {
            name: 'Only Button',
            cover: require('@/assets/edmTemplate/section-btn-finmas.jpg'),
            type: sectionType.ButtonElement
          }
        ]
      }
    case COMPANY_ID.finizi:
      return {
        defaultImage: {
          ...sharedImages,
          companyLogo: `${getCoreApiBaseUrl(env)}/crm/logo_finizi.png`
        },
        defaultLink: {
          officialUrl: 'https://finizi.vn/',
          googlePlay:
            'https://play.google.com/store/apps/details?id=com.oriente.finizi&hl=vi',
          appStore:
            'https://itunes.apple.com/us/app/finizi-vay-ti%E1%BB%81n-tin-c%E1%BA%ADy/id1445657192?ls=1&mt=8',
          facebook: 'https://facebook.com/finizivn'
        },
        customerService: {
          email: 'hotro@finizi.vn',
          phone: '1900 63 6899'
        },
        countryCode: '84',
        template: {
          header: finiziHeaderHtml,
          footer: finiziFooterHtml
        },
        style: {
          color: {
            primary: '#0069A7',
            secondary: '#0069A7'
          }
        },
        taskChannels: ['SMS', 'EDM'],
        edmComponents: [
          ...sharedBuilderSections,
          {
            name: 'Only Button',
            cover: require('@/assets/edmTemplate/section-btn-finizi.jpg'),
            type: sectionType.ButtonElement
          }
        ]
      }

    default:
      console.error(`Company not valid: ${companyId}`)
  }
}

export {
  VARIABLES,
  TEMPLATE_KEYS,
  STATUS,
  EXECUTE_STATUS,
  DEFAULT_PAGE_SIZE,
  dataPlatform,
  COMPANY_ID,
  getRecordDataUrl,
  getCompanyConfig
}
