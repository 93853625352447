import store from '../configs/store'

export const ROLES = {
  admin: 1,
  companyAdmin: 2,
  supervisorOfMarketer: 3,
  compliance: 4,
  supervisorOfResource: 5,
  marketer: 6,
  resource: 7
}

export const PUBLIC = 'PUBLIC'
export const REQUIRED_LOGIN = 'REQUIRED_LOGIN'
export const REQUIRED_LOGOUT = 'REQUIRED_LOGOUT'
export const ALL = '*'

export const SUPERVISORS = [
  ROLES.admin,
  ROLES.companyAdmin,
  ROLES.supervisorOfMarketer,
  ROLES.supervisorOfResource
]

// Module - Action
export const uiRoleAuthenticateMapping = {
  CampaignCreate: [ROLES.marketer],
  CampaignEdit: [ROLES.marketer],
  CampaignDelete: [ROLES.marketer],
  TaskCreate: [ROLES.marketer],
  TaskEdit: [ROLES.marketer],
  TaskDelete: [ROLES.marketer],
  TaskPause: [ROLES.resource, ROLES.supervisorOfResource, ROLES.admin],
  TaskReTrigger: [ROLES.marketer, ROLES.supervisorOfMarketer, ROLES.admin],
  TemplateRead: [
    ROLES.marketer,
    ROLES.supervisorOfMarketer,
    ROLES.companyAdmin,
    ROLES.admin
  ],
  TemplateCreate: [ROLES.marketer, ROLES.supervisorOfMarketer],
  TemplateEdit: [ROLES.marketer, ROLES.supervisorOfMarketer],
  TemplateUpdate: [ROLES.marketer, ROLES.supervisorOfMarketer],
  TemplateDelete: [ROLES.marketer, ROLES.supervisorOfMarketer],
  Frequency: [ROLES.resource, ROLES.supervisorOfResource, ROLES.admin],
  RoleRead: SUPERVISORS
}

export const authenticate = moduleAction => {
  const { role: userRole } = store.user
  const allowedRoles = uiRoleAuthenticateMapping[moduleAction]
  return !allowedRoles || allowedRoles.some(role => role === userRole)
}
