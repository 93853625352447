// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("assets/logo_crm.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("assets/branding/logo_cashalo.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("assets/branding/logo_finizi.png");
var ___CSS_LOADER_URL_IMPORT_3___ = require("assets/branding/logo_finmas.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
exports.push([module.id, ".btn-style[data-v-35f3a2c1] {\n  color: #fff !important;\n  border-radius: 4px;\n}\n.background-contain[data-v-35f3a2c1],\n.TopNav_logo .logo[data-v-35f3a2c1] {\n  background-size: contain;\n  background-position: center center;\n  background-repeat: no-repeat;\n}\n.TopNav_logo[data-v-35f3a2c1] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n}\n.TopNav_logo .logo[data-v-35f3a2c1] {\n  height: 20px;\n  width: 60px;\n}\n.TopNav_logo .logo.logo-crm[data-v-35f3a2c1] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.TopNav_logo .logo.brand-cashalo[data-v-35f3a2c1] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n.TopNav_logo .logo.brand-finizi[data-v-35f3a2c1] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n}\n.TopNav_logo .logo.brand-finmas[data-v-35f3a2c1] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");\n}\n", ""]);
// Exports
module.exports = exports;
