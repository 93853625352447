<template>
  <v-app :class="theme">
    <v-navigation-drawer
      v-model="drawer"
      :width="185"
      :right="false"
      class="bgPurple SideNavBtn"
      app
      fixed
      mobile-break-point="500"
      clipped
      dark
    >
      <side-nav />
      <time-zone />
    </v-navigation-drawer>

    <top-nav />

    <v-content>
      <v-container
        fluid
        fill-height
      >
        <v-layout
          column
          wrap
        >
          <v-flex
            align-start
            justify-start
            fill-height
          >
            <router-view />
          </v-flex>
        </v-layout>
      </v-container>
    </v-content>
    <div :class="{ loader: true, hide: !$store.loading }">
      <v-progress-linear
        indeterminate
        color="primary"
      />
    </div>
  </v-app>
</template>

<script>
import TopNav from '../components/TopNav'
import SideNav from '../components/SideNav'
import TimeZone from '../components/TimeZone'
import company from '@/mixins/company'

export default {
  components: {
    TopNav,
    SideNav,
    TimeZone
  },
  mixins: [company],
  data () {
    return {
      drawer: true
    }
  },
  computed: {
    theme () {
      return `theme--${this.companyId}`
    }
  },
  created () {
    this.$bus.$on('lazybee-toggle-drawer', this.toggleDrawer)
  },
  methods: {
    toggleDrawer () {
      this.drawer = !this.drawer
    }
  }
}
</script>
