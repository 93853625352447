import { getCookie } from '@/auth/cookie'
import { getCompanyConfig } from '@/const'

export default {
  data () {
    const companyId = getCookie('oCompany')
    return {
      company: getCompanyConfig({ companyId, env: this.$store.env }),
      companyId
    }
  }
}
