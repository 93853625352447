var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.node.visible),expression:"node.visible"}],ref:"node",staticClass:"tree-node",class:{
    'is-expanded': _vm.expanded,
    'is-current': _vm.tree.store.currentNode === _vm.node,
    'is-hidden': !_vm.node.visible,
    'is-focusable': !_vm.node.disabled,
    'is-checked': !_vm.node.disabled && _vm.node.checked
  },attrs:{"role":"treeitem","tabindex":"-1","aria-expanded":_vm.expanded,"aria-disabled":_vm.node.disabled,"aria-checked":_vm.node.checked,"draggable":_vm.tree.draggable},on:{"click":function($event){$event.stopPropagation();return _vm.handleClick.apply(null, arguments)},"dragstart":function($event){$event.stopPropagation();return _vm.handleDragStart.apply(null, arguments)},"dragover":function($event){$event.stopPropagation();return _vm.handleDragOver.apply(null, arguments)},"dragend":function($event){$event.stopPropagation();return _vm.handleDragEnd.apply(null, arguments)},"drop":function($event){$event.stopPropagation();return _vm.handleDrop.apply(null, arguments)}}},[_c('div',{staticClass:"tree-node-content",style:({ 'padding-left': (_vm.node.level - 1) * _vm.tree.indent + 'px' })},[_c('v-btn',{staticClass:"material-icons tree-node-expand-icon",class:{ 'is-leaf': _vm.node.isLeaf, expanded: !_vm.node.isLeaf && _vm.expanded },attrs:{"icon":"","flat":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleExpandIconClick.apply(null, arguments)}}},[_c('v-icon',[_vm._v("keyboard_arrow_right")])],1),_c('span',{staticClass:"tree-node-label"},[_vm._v(_vm._s(_vm.node.label))])],1),(!_vm.renderAfterExpand || _vm.childNodeRendered)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.expanded),expression:"expanded"}],staticClass:"tree-node-children",attrs:{"role":"group","aria-expanded":_vm.expanded}},_vm._l((_vm.node.childNodes),function(child){return _c('tree-node',{key:_vm.getNodeKey(child),attrs:{"render-content":_vm.renderContent,"render-after-expand":_vm.renderAfterExpand,"node":child},on:{"node-expand":_vm.handleChildNodeExpand}})}),1):_vm._e()])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }