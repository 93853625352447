<template>
  <v-list subheader>
    <!-- CRM logo & company name -->
    <div class="logoWrap hidden-md-and-up">
      <div class="logo logo-crm display-inlineBlock">
        <img src="~assets/logo_crm.svg">
      </div>
      <hr>
      <p class="caption mt-1">
        Company :
        <span class="companyName">{{ company.name }}</span>
      </p>
    </div>

    <!-- First layer -->
    <template v-for="item in menu">
      <v-divider
        v-if="item.divider"
        :key="item.name"
      />
      <v-list-tile
        v-else-if="!item.list"
        :key="item.name"
        :to="item.path || (item.pathName ? { name: item.pathName } : '')"
        class="bgPurple_itemStyle"
        @click.native="
          event => {
            itemClick(event, item)
          }
        "
      >
        <v-list-tile-action v-if="item.icon">
          <v-icon small>
            {{ item.icon }}
          </v-icon>
        </v-list-tile-action>
        <v-list-tile-title>{{ item.name }}</v-list-tile-title>
      </v-list-tile>
      <v-list-group
        v-else
        :key="item.name"
        v-model="item.isActive"
        :prepend-icon="item.icon"
        no-action
      >
        <v-list-tile
          slot="activator"
          :to="item.path || (item.pathName ? { name: item.pathName } : '')"
          exact
          @click.native="
            event => {
              itemClick(event, item)
            }
          "
        >
          <v-list-tile-title>{{ item.name }}</v-list-tile-title>
        </v-list-tile>
      </v-list-group>
    </template>
  </v-list>
</template>

<script>
import config from './config'
import { authenticate } from '../../auth/allowAuthMapping.js'

export default {
  data () {
    return {
      company: {
        name: this.$store.user.company
      }
    }
  },
  computed: {
    menu: function () {
      const { name: pathName, path } = this.$router.currentRoute

      const setIsActive = item => {
        const obj = {
          ...item,
          isActive:
            item.path === path || (pathName && item.pathName === pathName)
        }
        if (item.list) {
          obj.list = item.list.map(setIsActive)
          obj.isActive = obj.isActive || obj.list.some(item => item.isActive)
        }
        return obj
      }
      return config
        .filter(item => authenticate(item.functionName))
        .map(setIsActive)
    }
  },
  methods: {
    itemClick (event, item) {
      if (typeof item.onClick === 'function') {
        item.onClick(event, item, this)
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.logoWrap {
  margin: 0 30px 15px;

  .logo {
    &.logo-crm {
      width: 50px;
      height: 18px;
      margin-bottom: 10px;
      filter: brightness(5);
    }
  }

  hr {
    filter: brightness(0.6);
  }

  p {
    &.caption {
      color: $silver;

      .companyName {
        display: inline-block;
        text-transform: capitalize;
      }
    }
  }
}
</style>
