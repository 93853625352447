<template>
  <div class="simple-layout">
    <x-layout v-bind="layoutScheme">
      <div slot="header">
        <div class="logo">
          <img src="~assets/logo_crm_white.svg">
        </div>
        <p class="subtitle">
          {{ subtitle }}
        </p>
      </div>

      <router-view slot="content" />

      <div slot="footer">
        <div class="copyright">
          {{ copyright }}
        </div>
      </div>
    </x-layout>
    <div :class="{ loader: true, hide: !$store.loading }">
      <v-progress-linear
        indeterminate
        color="primary"
      />
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      layoutScheme: {
        type: 'simple'
      },
      subtitle: 'Oriente Customer Relationship Management System',
      copyright: '© 2019 Oriente. All Rights Reserved.'
    }
  }
}
</script>

<style lang="stylus" scope>
.simple-layout {
  background-image:
    url("~assets/landing_bg.webp"),
    linear-gradient(15deg, #2d2665, #e4fff9);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  .logo {
    text-align: center;
    img {
      width: 140px;
      display: inline-block;
    }
  }

  .subtitle{
    color: $white;
    text-align: center;
    margin: 16px auto 0;
    font-weight: 700;
  }

  .copyright {
    text-align: center
    margin-top: 16px;
  }
}

@media screen and (max-width: 500px) {
.simple-layout {
  .logo {
    img {
      width: 86px;
    }
  }
  p.subtitle {
    margin: 16px auto;
  }
}
}
</style>
