<template>
  <div class="TimeZone">
    <v-chip
      small
      disabled
      color="blue-grey darken-4"
      text-color="white"
    >
      <v-avatar>
        <v-icon small>
          language
        </v-icon>
      </v-avatar>Time Zone
    </v-chip>
    <div>{{ currentTimezone }} Time</div>
    <div>{{ displayTime }}</div>
  </div>
</template>

<script>
import { formatDatetimeFromISOString } from '@/utils/date'
import { getCookie } from '@/auth/cookie'
const TICK_OFFSET = 1000

export default {
  data () {
    return {
      timer: {},
      currentTimezone: getCookie('oTimezone'),
      currentTime: new Date()
    }
  },
  computed: {
    displayTime () {
      return formatDatetimeFromISOString(this.currentTime.toISOString())
    }
  },
  mounted () {
    this.startTimer()
  },
  beforeDestroy () {
    clearInterval(this.timer)
  },
  methods: {
    startTimer () {
      this.timer = setInterval(() => {
        this.currentTime = new Date()
      }, TICK_OFFSET)
    }
  }
}
</script>

<style lang="stylus" scoped>
.TimeZone {
  width: 135px;
  margin-left: 25px;
  padding-bottom: 3px;
  position: fixed;
  bottom: 25px;
  border-bottom: 1px solid $silver;

  .v-chip {
    width: 100%;
    font-size: $fs-mini;
    margin-left: -1px;
  }

  div {
    color: $white;
    font-size: $fs-small;
  }
}
</style>
