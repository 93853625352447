<template>
  <v-toolbar
    app
    pale-grey-two
    fixed
    clipped-left
    color="white"
    class="TopNav_toolbar"
  >
    <v-toolbar-title class="TopNav_logo align-center hidden-sm-and-down">
      <!-- CRM logo -->
      <a class="logo logo-crm mr-3" />
      <!-- Brand logo -->
      <a :class="`logo brand-${company}`" />
    </v-toolbar-title>

    <v-toolbar-title class="logo-title" />
    <v-toolbar-side-icon @click="toggleDrawer" />
    <v-spacer />
    <v-toolbar-items>
      <account-menu />
    </v-toolbar-items>
  </v-toolbar>
</template>

<script>
import AccountMenu from '../AccountMenu'

export default {
  components: {
    AccountMenu
  },
  data () {
    return {
      company: this.$store.user.company
    }
  },
  methods: {
    toggleDrawer () {
      this.$bus.emit('lazybee-toggle-drawer')
    }
  }
}
</script>

<style lang="stylus" scoped>
@import 'extend.styl'

.TopNav_logo {
  display: flex;

  .logo {
    height: 20px;
    width: 60px;
    @extend .background-contain;

    &.logo-crm {
      background-image: url('~assets/logo_crm.svg');
    }

    &.brand-cashalo {
      background-image: url('~assets/branding/logo_cashalo.png');
    }

    &.brand-finizi {
      background-image: url('~assets/branding/logo_finizi.png');
    }

    &.brand-finmas {
      background-image: url('~assets/branding/logo_finmas.svg');
    }
  }
}
</style>
