<template>
  <x-menu v-bind="menuScheme">
    <v-btn
      slot="trigger"
      class="text-xs-right text-lowercase"
      flat
    >
      <div class="pr-1">
        <v-tooltip bottom>
          <v-icon
            slot="activator"
            :class="$style.notifications"
            @click="refreshPage"
          >
            notifications
          </v-icon>
          <div>
            New version has been released!<br>Click here to reload the page
          </div>
        </v-tooltip>
        <span
          class="text-capitalize color-blueyGrey caption font-weight-bold"
        >{{ $t(`Role.types[${userRole}]`) }}
        </span>
        <br>
        <span class="userEmail">
          {{ userEmail }}
        </span>
      </div>
      <v-icon dark>
        keyboard_arrow_down
      </v-icon>
    </v-btn>
  </x-menu>
</template>

<script>
import config from './config'
import { logout } from '../../auth/apis'

export default {
  data () {
    return {
      userEmail: this.$store.user.email || '--',
      userRole: this.$store.user.role || '--',
      menuScheme: {
        menuList: {
          params: this,
          list: config
        }
      }
    }
  },
  methods: {
    linkTo (router) {
      this.$router.push(router)
    },
    async logout () {
      this.$store.loading = true
      try {
        await logout()
        this.$router.push('/login')
      } catch (e) {
        this.$store.errorNotification = e
      }
      this.$store.loading = false
    },
    refreshPage () {
      window.location.reload()
    }
  }
}
</script>

<style lang="stylus" scoped>

@media only screen and (max-width: 959px) {
  .v-menu {
    .userEmail {
      font-size: $fs-mini;
    }
  }
}
</style>

<style lang="stylus" module>
:global(body.request-update) .notifications {
  display: inline-block !important;
}
.notifications {
  font-size: 1rem !important;
  display: none !important;
  margin: 0 6px !important;
  color: #ffb23d !important;
}
</style>
