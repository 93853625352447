import Vue from 'vue'

export default {
  async users (params) {
    const response = await Vue.$http({
      method: 'post',
      url: '/users',
      data: params
    })

    return response.data
  }
}
