import Vue from 'vue'
import { appendCompanyToParams } from '@/utils/service'
import { getCookie } from './cookie'

export const login = appendCompanyToParams(params =>
  Vue.$http({
    method: 'post',
    url: `/api/v1/companies/${params.companyId}/login`,
    data: params
  })
)

export const logout = appendCompanyToParams(async params => {
  const res = await Vue.$http({
    method: 'delete',
    url: `/api/v1/companies/${params.companyId}/logout`
  })
  window.document.cookie = 'oCompany='
  return res
})

export const getCurrentUser = appendCompanyToParams(params =>
  Vue.$http({
    method: 'get',
    url: `/api/v1/companies/${params.companyId}/me`
  })
)

export const resetPassword = (password, user, companyId) => {
  // FIXME: implement this function
  const token = getCookie('t')
  const { id } = user
  const params = {
    token,
    id,
    fields: { password }
  }
  return Vue.$http({
    method: 'post',
    url: `/api/v1/companies/${companyId}/login`,
    data: params
  })
}
