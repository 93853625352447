import services from './services'
import routes from './routes'

export default {
  name: 'Campaign',
  layout: 'Admin',
  routes,
  services,
  langs: {
    en: require('./langs/en')
  }
}
