import { ALL, REQUIRED_LOGIN } from '../../auth/allowAuthMapping'
const Profile = () =>
  import(/* webpackChunkName: "Account" */ './pages/Profile.vue')
const Setting = () =>
  import(/* webpackChunkName: "Account" */ './pages/Setting.vue')

export default {
  path: 'account',
  component: { render: h => h('router-view') },
  children: [
    {
      path: 'profile',
      component: Profile,
      meta: {
        allowedRoles: [ALL],
        allowedAuth: REQUIRED_LOGIN
      }
    },
    {
      name: 'accountSetting',
      path: 'setting',
      component: Setting,
      meta: {
        allowedRoles: [ALL],
        allowedAuth: REQUIRED_LOGIN
      }
    },
    {
      path: 'secret',
      component: Setting,
      meta: {
        allowedRoles: [ALL],
        allowedAuth: REQUIRED_LOGIN
      }
    }
  ]
}
