var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.authority !== 'invisible')?_c('div',[_c('el-tabs',{attrs:{"type":_vm.tabType,"tab-position":_vm.position},on:{"tab-click":_vm.tabClick},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},_vm._l((_vm.tabs),function(tab,index){return _c('el-tab-pane',{key:index,attrs:{"label":tab.label}},[_vm._t(tab.slot)],2)}),1),(_vm.control)?_c('div',{staticClass:"tabs-control"},[_c('x-button',_vm._b({},'x-button',{
      content: _vm.prev.content,
      color: _vm.prev.color,
      round: _vm.prev.round,
      click: _vm.prevTab
    },false)),_c('x-button',_vm._b({},'x-button',{
      content: _vm.next.content,
      color: _vm.next.color,
      round: _vm.next.round,
      click: _vm.nextTab
    },false))],1):_vm._e()],1):_vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }