import Vue from 'vue'
import axios from 'axios'
import modules from 'modules'
import helper from './helper'

axios.defaults.timeout = 120000

// config backend server url

const validateVersion = response => {
  const key = 'version'
  const localVersion = window.localStorage.getItem(key)
  const remoteVersion = response.headers['version']
  if (localVersion && localVersion !== remoteVersion) {
    document.querySelector('body').classList.add('request-update')
  }
  window.localStorage.setItem(key, remoteVersion)
}

Vue.$http = axios

axios.interceptors.response.use(
  response => {
    validateVersion(response)
    return response
  },
  error => {
    const { message } = (error.response && error.response.data) || {}
    console.error(message || error)
    return Promise.reject(message || error)
  }
)

modules.forEach(module => {
  helper.registerService(Vue.prototype, module)
})
