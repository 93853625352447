export const sectionType = {
  TextElement: 'TextElement',
  ImageElement: 'ImageElement',
  ButtonElement: 'ButtonElement',
  HeaderElement: 'HeaderElement',
  ImageFullWidth: 'ImageFullWidth',
  Image2Column: 'Image2Column',
  Image3Column: 'Image3Column'
}

export const builderEvent = {
  linkDialogCallback: 'linkDialogCallback',
  linkDialogRemove: 'linkDialogRemove',
  sectionSaveContent: 'sectionSaveContent',
  sectionLinkDialog: 'sectionLink-dialog'
}

export const dialogLinkConfig = {
  value: false,
  cancelText: null,
  confirmText: 'SUBMIT',
  closeSign: true,
  maxWidth: '700px'
}

export const buttonChangeImg = {
  content: 'image',
  icon: true,
  borderRadius: 4,
  color: '#000',
  size: 'small'
}

export const buttonEdmLinkto = {
  content: 'link',
  icon: true,
  borderRadius: 4,
  color: '#000',
  size: 'small'
}

export const generateSectionId = () => {
  return (Date.now() + Math.random()).toString()
}

export const rowSectionObj = (type, style = '', id) => {
  if (type === 'Image2Column' || type === 'Image3Column') {
    return {
      id: id || generateSectionId(),
      type: type,
      style: style,
      data: {
        0: {
          text: '',
          img: null,
          link: {}
        },
        1: {
          text: '',
          img: null,
          link: {}
        },
        2: {
          text: '',
          img: null,
          link: {}
        }
      }
    }
  }
  return {
    id: id || generateSectionId(),
    type: type,
    style: style,
    data: {
      text: '',
      img: null,
      link: {}
    }
  }
}

export const DEFAULT_LINK_TITLE = 'Text link'
export const DEFAULT_BUTTON_TITLE = 'Single Button'
export const DEFAULT_SECTION_TEXT =
  'Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque eligendi, facilis dicta consequuntur quis vero obcaecati deserunt et ullam cum voluptatibus aliquid sit, natus omnis recusandae corrupti soluta placeat voluptates!'
export const DEFAULT_SECTION_EDM_TITLE =
  'Hi <span contenteditable="false">{{receiverName}}</span>'

export const DEFAULT_SIZE = {
  previewPcWidth: 635,
  previewMobileWidth: 360
}
