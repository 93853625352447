import Vue from 'vue'
import serviceWrapper from '@/utils/service'

const preflight = (params = {}) =>
  Vue.$http({
    method: 'post',
    url: `/api/v1/companies/${params.companyId}/preflight`,
    data: params
  })

const createTask = (params = {}) =>
  Vue.$http({
    method: 'post',
    url: `/api/v1/companies/${params.companyId}/tasks`,
    data: params
  })

const updateTask = (params = {}) =>
  Vue.$http({
    method: 'patch',
    url: `/api/v1/companies/${params.companyId}/tasks/${params.id}`,
    data: params
  })

const fetchTaskList = (params = {}, offset = 0, limit = 10) =>
  Vue.$http({
    method: 'get',
    url: `/api/v1/companies/${params.companyId}/tasks`,
    params: {
      offset,
      limit,
      ...params
    }
  })

const fetchTaskDetail = (params = {}) =>
  Vue.$http({
    method: 'get',
    url: `/api/v1/companies/${params.companyId}/tasks/${params.taskId}`
  })

const deleteTask = (params = {}) =>
  Vue.$http({
    method: 'delete',
    url: `/api/v1/companies/${params.companyId}/tasks/${params.id}`
  })

const pauseTask = (params = {}) =>
  Vue.$http({
    method: 'post',
    url: `/api/v1/companies/${params.companyId}/tasks/${params.taskId}/pause`
  })

const fetchJobList = (params = {}, offset = 0, limit = 10) =>
  Vue.$http({
    method: 'get',
    url: `/api/v1/companies/${params.companyId}/tasks/${params.taskId}/jobs`,
    params: {
      offset,
      limit,
      ...params
    }
  })

const restartTask = (params = {}) =>
  Vue.$http({
    method: 'post',
    url: `/api/v1/companies/${params.companyId}/tasks/${params.taskId}/start`,
    data: {
      forceRestartTask: true
    }
  })

export default serviceWrapper({
  createTask,
  deleteTask,
  fetchTaskList,
  fetchTaskDetail,
  updateTask,
  pauseTask,
  preflight,
  fetchJobList,
  restartTask
})
