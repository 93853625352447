import Vue from 'vue'
import serviceWrapper from '@/utils/service'

const fetchTemplateList = (params = {}, offset = 0, limit = 10) =>
  Vue.$http({
    method: 'get',
    url: `/api/v1/companies/${params.companyId}/templates`,
    params: {
      offset,
      limit,
      ...params
    }
  })

const fetchTemplate = (params = {}) =>
  Vue.$http({
    method: 'get',
    url: `/api/v1/companies/${params.companyId}/templates/${params.templateId}`
  })

const createTemplate = (params = {}) =>
  Vue.$http({
    method: 'post',
    url: `/api/v1/companies/${params.companyId}/templates`,
    data: params
  })

const updateTemplate = (params = {}) =>
  Vue.$http({
    method: 'patch',
    url: `/api/v1/companies/${params.companyId}/templates/${params.id}`,
    data: params
  })

const deleteTemplate = (params = {}) =>
  Vue.$http({
    method: 'delete',
    url: `/api/v1/companies/${params.companyId}/templates/${params.id}`
  })

const createShortLink = (params = {}) =>
  Vue.$http({
    method: 'post',
    url: `/api/v1/companies/${params.companyId}/shortlink`,
    data: params
  })

const fetchDeepLinks = (params = {}) =>
  Vue.$http({
    method: 'get',
    url: `/api/v1/companies/${params.companyId}/deepLinks`,
    data: params
  })

export default serviceWrapper({
  createShortLink,
  fetchTemplateList,
  fetchTemplate,
  createTemplate,
  updateTemplate,
  deleteTemplate,
  fetchDeepLinks
})
