var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.type === 'horizontal')?_c('v-stepper',{attrs:{"alt-labels":_vm.alertLabel},model:{value:(_vm.innerActive),callback:function ($$v) {_vm.innerActive=$$v},expression:"innerActive"}},[_c('v-stepper-header',[_vm._l((_vm.steps),function(stepItem,index){return [_c('v-stepper-step',{key:index,attrs:{"step":index + 1,"complete":_vm.innerActive > (index + 1)}},[_vm._v(_vm._s(stepItem.label || _vm.UNKNOW_STEPPER))]),(index !== (_vm.count - 1))?_c('v-divider',{key:index + 10}):_vm._e()]})],2),_c('v-stepper-items',_vm._l((_vm.steps),function(stepItem,index){return _c('v-stepper-content',{key:index,attrs:{"step":index + 1}},[_vm._t(stepItem.slot)],2)}),1)],1):_c('v-stepper',{attrs:{"vertical":""},model:{value:(_vm.innerActive),callback:function ($$v) {_vm.innerActive=$$v},expression:"innerActive"}},[_vm._l((_vm.steps),function(item,index){return [_c('v-stepper-step',{key:index,attrs:{"step":index + 1,"complete":_vm.innerActive > (index + 1)}},[_vm._v(_vm._s(item.label))]),_c('v-stepper-content',{key:index,attrs:{"step":index + 1}},[_vm._t(item.slot)],2)]})],2),(_vm.actions)?_c('div',{staticClass:"action-area"},[(_vm.innerActive != 1)?_c('x-button',_vm._b({staticClass:"prev-btn",attrs:{"outline":""}},'x-button',{
      content: _vm.prev.content,
      color: _vm.prev.color,
      click: _vm.goPrevious
    },false)):_vm._e(),_c('x-button',_vm._b({class:_vm.innerActive != _vm.count ? 'next-btn' : 'finish-btn'},'x-button',{
      content: _vm.innerActive != _vm.count ? _vm.next.content : _vm.finish.content,
      color: _vm.next.color,
      click: _vm.goNext
    },false))],1):_vm._e()],1)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }