<template>
  <div
    v-if="notifications.length"
    class="notificationWrapper"
  >
    <v-alert
      v-for="notif in notifications"
      :key="notif.timestamp"
      :type="notif.type"
      :value="true"
      :dismissible="true"
    >
      {{ notif.message }}
    </v-alert>
  </div>
</template>

<script>
const CLOSE_NOTIF_TIME = 20 // Should align with closeNotifTime in style

export default {
  props: {
    success: {
      type: String,
      default: ''
    },
    error: {
      type: [String, Error],
      default: ''
    },
    resetSuccessNotification: {
      type: Function,
      default: () => {}
    },
    resetErrorNotification: {
      type: Function,
      default: () => {}
    }
  },
  data: function () {
    return {
      notifications: []
    }
  },
  watch: {
    success (newValue) {
      if (!newValue) return
      const timestamp = new Date().valueOf()
      this.notifications.push({
        message: newValue,
        type: 'success',
        timestamp
      })
      this.resetSuccessNotification(newValue)
      this.resetNotifications(timestamp)
    },
    error (newValue) {
      if (!newValue) return
      const timestamp = new Date().valueOf()
      this.notifications.push({
        message: typeof newValue === 'string' ? newValue : newValue.toString(),
        type: 'error',
        timestamp
      })
      console.warn(newValue)
      this.resetErrorNotification(newValue)
      this.resetNotifications(timestamp)
    }
  },
  methods: {
    resetNotifications (timestamp) {
      setTimeout(() => {
        this.notifications = this.notifications.filter(
          x => x.timestamp !== timestamp
        )
      }, CLOSE_NOTIF_TIME * 1000)
    }
  }
}
</script>

<style lang="stylus" scoped>
$notifHeight = 55px;
$closeNotifTime = 20s; // Should align with CLOSE_NOTIF_TIME in script
$animationDuration = 0.3s;

.notificationWrapper {
  position: fixed;
  z-index: 202;
  width: 80%;
  text-align: center;
  margin: 0 10%;

  & >>> .v-alert {
    overflow: hidden;
    opacity: 0;
    border: 0;
    height: 0;
    padding: 0;
    margin: 0;
    box-shadow: inset 0px 4px 0px 0px rgba(0, 0, 0, 0.12);
    animation: $closeNotifTime fadein;

    .v-alert__icon {
      margin-left: 16px;
    }
    .v-alert__dismissible {
      display: flex;
      align-self: center;
      margin-right: 16px;
    }
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
    height: $notifHeight;
    margin: 0px auto 4px;
  }

  {(100% * $animationDuration / $closeNotifTime)} {
    opacity: 1;
  }

  {100% * (1 - $animationDuration / $closeNotifTime)} {
    opacity: 1;
    height: $notifHeight;
    margin: 0px auto 4px;
  }

  100% {
    opacity: 0;
    height: 0;
    margin: 0;
  }
}
</style>
