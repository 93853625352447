import {
  REQUIRED_LOGIN,
  uiRoleAuthenticateMapping
} from '../../auth/allowAuthMapping'
const RoleList = () =>
  import(/* webpackChunkName: "Role" */ './pages/RoleList.vue')
const CreateRole = () =>
  import(/* webpackChunkName: "Role" */ './pages/CreateRole.vue')

export default {
  path: 'roles',
  component: { render: h => h('router-view') },
  meta: {
    allowedRoles: uiRoleAuthenticateMapping.RoleRead,
    allowedAuth: REQUIRED_LOGIN
  },
  children: [
    {
      path: '',
      name: 'RoleList',
      component: RoleList,
      meta: {
        allowedRoles: uiRoleAuthenticateMapping.RoleRead,
        allowedAuth: REQUIRED_LOGIN
      }
    },
    {
      path: 'new',
      name: 'RoleCreate',
      component: CreateRole,
      meta: {
        allowedRoles: uiRoleAuthenticateMapping.RoleRead,
        allowedAuth: REQUIRED_LOGIN
      }
    }
  ]
}
