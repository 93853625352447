export const numberWithCommas = number => {
  return Number(number).toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 20
  })
}

export const numberToOrdinalString = number => {
  if (number < 0) throw new Error('Ordinal number only supports number >= 0')
  const suffixs = ['th', 'st', 'nd', 'rd']
  const suffix =
    number > 10 && number < 20 ? suffixs[0] : suffixs[number % 10] || suffixs[0]
  return `${number}${suffix}`
}

export const normalizeCount = number => {
  if (typeof number === 'number') return numberWithCommas(number)
  return '--'
}
