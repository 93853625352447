import routes from './routes'
import services from './services'

export default {
  name: 'Home',
  layout: 'Admin',
  routes,
  services,
  langs: {
    en: require('./langs/en')
  }
}
