import Vue from 'vue'
import VueBus from 'vue-bus'
import { Tabs, TabPane, Upload } from 'element-ui'
import {
  Vuetify,
  VAlert,
  VApp,
  VAutocomplete,
  VAvatar,
  VBtn,
  VCard,
  VCheckbox,
  VChip,
  VCombobox,
  VDataTable,
  VDatePicker,
  VDialog,
  VDivider,
  VExpansionPanel,
  VForm,
  VGrid,
  VIcon,
  VImg,
  VList,
  VMenu,
  VNavigationDrawer,
  VPagination,
  VProgressLinear,
  VRadioGroup,
  VSelect,
  VSnackbar,
  VStepper,
  VSwitch,
  VTextarea,
  VTextField,
  VTimePicker,
  VToolbar,
  VTooltip
} from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import LazybeeComponent from '../../lazybee/src/modules'
import '../../lazybee/src/styles/base.styl'
import 'styles/main.styl'

Vue.use(VueBus)
Vue.use(LazybeeComponent)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Upload)
Vue.use(Vuetify, {
  components: {
    VAlert,
    VApp,
    VAutocomplete,
    VAvatar,
    VBtn,
    VCard,
    VCheckbox,
    VChip,
    VCombobox,
    VDataTable,
    VDatePicker,
    VDialog,
    VDivider,
    VExpansionPanel,
    VForm,
    VGrid,
    VIcon,
    VImg,
    VList,
    VMenu,
    VNavigationDrawer,
    VPagination,
    VProgressLinear,
    VRadioGroup,
    VSelect,
    VSnackbar,
    VStepper,
    VSwitch,
    VTextarea,
    VTextField,
    VTimePicker,
    VToolbar,
    VTooltip
  }
})
