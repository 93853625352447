import Vue from 'vue'

export default {
  async getUserList (params) {
    const response = await Vue.$http({
      method: 'post',
      url: '/user',
      data: params
    })

    return response
  }
}
