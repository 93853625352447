// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "body.request-update .index_notifications_3IXiv {\n  display: inline-block !important;\n}\n.index_notifications_3IXiv {\n  font-size: 1rem !important;\n  display: none !important;\n  margin: 0 6px !important;\n  color: #ffb23d !important;\n}\n", ""]);
// Exports
exports.locals = {
	"notifications": "index_notifications_3IXiv"
};
module.exports = exports;
