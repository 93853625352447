import User from 'modules/User'
import Home from 'modules/Home'
import Account from 'modules/Account'
import Campaign from 'modules/Campaign'
import Task from 'modules/Task'
import Role from 'modules/Role'
import Template from 'modules/Template'
import Frequency from 'modules/Frequency'

export default [User, Home, Account, Campaign, Task, Role, Template, Frequency]
