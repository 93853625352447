<template>
  <div>
    <notification
      v-bind="notificationScheme"
      :reset-success-notification="resetSuccessNotification"
      :reset-error-notification="resetErrorNotification"
    />
    <confirm-dialog
      v-for="(confirmDialogScheme, index) in confirmDialog"
      :key="index"
      v-bind="confirmDialogScheme"
      :on-close="onDialogClose"
    />
    <router-view />
  </div>
</template>

<script>
import Notification from '../components/Notification.vue'
import ConfirmDialog from '../components/ConfirmDialog.vue'
export default {
  components: {
    Notification,
    ConfirmDialog
  },
  data () {
    return {}
  },
  computed: {
    notificationScheme () {
      return {
        success: this.$store.successNotification,
        error: this.$store.errorNotification
      }
    },
    confirmDialog: function () {
      return this.$store.confirmDialog
    }
  },
  methods: {
    resetSuccessNotification () {
      this.$store.successNotification = null
    },
    resetErrorNotification () {
      this.$store.errorNotification = null
    },
    onDialogClose () {
      this.$store.confirmDialog.shift()
    }
  }
}
</script>
