import {
  REQUIRED_LOGIN,
  uiRoleAuthenticateMapping
} from '../../auth/allowAuthMapping'
const TemplateList = () =>
  import(/* webpackChunkName: "Template" */ './pages/TemplateList.vue')
const EdmBuilderPage = () =>
  import(/* webpackChunkName: "Template" */ './pages/EdmBuilderPage.vue')
const EditsmsTemplate = () =>
  import(/* webpackChunkName: "Template" */ './pages/EditsmsTemplate.vue')
const CreateAppPush = () =>
  import(/* webpackChunkName: "Template" */ './pages/CreateAppPush.vue')
const EditAppPush = () =>
  import(/* webpackChunkName: "Template" */ './pages/EditAppPush.vue')
const { TemplateRead, TemplateEdit } = uiRoleAuthenticateMapping

export default {
  path: 'templates',
  component: { render: h => h('router-view') },
  children: [
    {
      path: '',
      name: 'TemplateList',
      component: TemplateList,
      meta: {
        allowedRoles: TemplateRead,
        allowedAuth: REQUIRED_LOGIN
      }
    },
    {
      path: 'edm/new',
      name: 'TemplateCreateEdm',
      component: EdmBuilderPage,
      meta: {
        allowedRoles: TemplateEdit,
        allowedAuth: REQUIRED_LOGIN
      }
    },
    {
      path: 'edm/:id/edit',
      name: 'TemplateEditEdm',
      component: EdmBuilderPage,
      meta: {
        allowedRoles: TemplateEdit,
        allowedAuth: REQUIRED_LOGIN
      }
    },
    {
      path: 'sms/new',
      name: 'TemplateCreateSms',
      component: EditsmsTemplate,
      meta: {
        allowedRoles: TemplateEdit,
        allowedAuth: REQUIRED_LOGIN
      }
    },
    {
      path: 'sms/:templateId/edit',
      name: 'TemplateEditSms',
      component: EditsmsTemplate,
      meta: {
        allowedRoles: TemplateEdit,
        allowedAuth: REQUIRED_LOGIN
      },
      props: true
    },
    {
      path: 'apppush/new',
      name: 'TemplateCreateAppPush',
      component: CreateAppPush,
      meta: {
        allowedRoles: TemplateEdit,
        allowedAuth: REQUIRED_LOGIN
      }
    },
    {
      path: 'apppush/:id/edit',
      name: 'TemplateEditAppPush',
      component: EditAppPush,
      meta: {
        allowedRoles: TemplateEdit,
        allowedAuth: REQUIRED_LOGIN
      },
      props: true
    }
  ]
}
