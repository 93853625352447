import {
  ALL,
  REQUIRED_LOGIN,
  uiRoleAuthenticateMapping
} from '../../auth/allowAuthMapping'
const TaskList = () =>
  import(/* webpackChunkName: "Task" */ './pages/TaskList.vue')
const CreateTask = () =>
  import(/* webpackChunkName: "Task" */ './pages/CreateTask.vue')
const EditTask = () =>
  import(/* webpackChunkName: "Task" */ './pages/EditTask.vue')
const TaskSchedule = () =>
  import(/* webpackChunkName: "Task" */ './pages/TaskSchedule.vue')

export default {
  path: 'tasks',
  component: { render: h => h('router-view') },
  children: [
    {
      path: '',
      name: 'TaskList',
      component: TaskList,
      meta: {
        allowedRoles: [ALL],
        allowedAuth: REQUIRED_LOGIN
      }
    },
    {
      path: 'new',
      name: 'TaskCreate',
      component: CreateTask,
      meta: {
        allowedRoles: uiRoleAuthenticateMapping.TaskCreate,
        allowedAuth: REQUIRED_LOGIN
      },
      props: true
    },
    {
      path: ':taskId/edit',
      name: 'TaskEdit',
      component: EditTask,
      meta: {
        allowedRoles: uiRoleAuthenticateMapping.TaskEdit,
        allowedAuth: REQUIRED_LOGIN
      },
      props: true
    },
    {
      path: ':taskId',
      name: 'TaskDetail',
      component: EditTask,
      meta: {
        allowedRoles: [ALL],
        allowedAuth: REQUIRED_LOGIN
      },
      props: true
    },
    {
      path: ':taskId/schedule',
      name: 'TaskSchedule',
      component: TaskSchedule,
      meta: {
        allowedRoles: [ALL],
        allowedAuth: REQUIRED_LOGIN
      },
      props: true
    }
  ]
}
